<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FDCE48"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#FDCE48" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Inventory </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start>
      <v-flex xs12 xl3 v-for="(item, i) in inventory" :key="i" pa-4>
        <v-card>
          <v-layout wrap justify-center pa-4>
            <v-flex xs3>
              <v-img
                src="./../../assets/icons/inventory.png"
                height="80px"
                contain
              ></v-img>
            </v-flex>
            <v-flex xs9 pl-2 align-self-center>
              <v-layout wrap justify-center>
                <v-flex xs12 text-left>
                  <span class="itemKey">Honey Type</span>
                  <span class="itemValue pl-2">{{ item.honeytype }}</span>
                </v-flex>
                <v-flex xs12 text-left>
                  <span class="itemKey">Price</span>
                  <span class="itemValue pl-2">
                    {{ item.price }}
                    <span v-if="!item.price">-</span>
                  </span>
                </v-flex>
                <v-flex xs12 text-left>
                  <span class="itemKey">Amount</span>
                  <span class="itemValue pl-2">{{ item.amount }}</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      inventory: {},
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/inventory/mine",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.inventory = response.data.data.inventory;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>